const colors = {
  primary: "#0065BC",
  secondary: "#F89C34",
  default: "#2A2E38",
  white: "#FFFFFF",
  gray: "rgba(255,255,255,.6)",
  grey: "rgba(0, 0, 0, 0.1)",
  red: "rgba(255, 0, 0, 0.4)",
  black: "#181818",
  gold: "#fdd891",
}

export default colors
