import { createGlobalStyle } from "styled-components"
import reset from "styled-reset-advanced"

export default createGlobalStyle`
  ${reset};
  
  body {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    background-color: #fcfcfc;
    width:100%;
  }
`
