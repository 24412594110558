import colors from "./colors"

const theme = {
  colors,
  breakpoints: ["40em", "52em", "64em"],
  fonts: {
    montserat: '"Avenir Next", "montserat", sans-serif',
    playfair: '"playfair", serif',
  },
  primaryDark: "#0D0C1D",
  primaryLight: "#EFFFFA",
  primaryHover: "#343078",
  mobile: "576px",
}

export default theme
