import PropTypes from "prop-types"
import React from "react"
// import Helmet from 'react-helmet';
import { graphql, StaticQuery } from "gatsby"
import styled, { ThemeProvider } from "styled-components"
import { theme } from "../../global/theme"
import GlobalStyle from "./GlobalStyle"

// import { Montserat, PlayFairDisplay } from '../Fonts';

// import GatsbyShitheadFix from "../GatsbyShitheadFix"
const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
`

const Layouter = ({ children }) => {
  // <GatsbyShitheadFix>

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          {/* <Helmet>
            <title>{data.site.siteMetadata.title}</title>
            <meta
              name="apple-mobile-web-app-status-bar-style"
              content="default"
            />
          </Helmet> */}
          <ThemeProvider theme={theme}>
            <>
              {/* <Montserat /> */}
              {/* <PlayFairDisplay /> */}
              <GlobalStyle />
              <Container>{children}</Container>
            </>
          </ThemeProvider>
        </>
      )}
    />
  )
  // </GatsbyShitheadFix>
}

Layouter.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layouter
